import { useEffect, useState } from "react";
import Link from "next/link";
import BestSeller from "./BestSeller";
import ProductCategory from "./ProductCategory";
import { Paginate } from "../../../models/model";
import Product, { IProduct } from "../../../models/product.model";
import { IUserBookmark } from "../../../models/userBookmark.model";
import FlashSaleSection from "@Pages/shop/components/FlashSaleSection";
import { FlashSale } from "models/flashSale.model";
import MenuItem, { IMenuItem } from "@Models/menuItem.model";

// const BestSeller = dynamic(() => import("./BestSeller"), {
//    // ssr: true,
//    loading: () => <div style={{ height: "312px" }} />
// });

// const ProductCategory = dynamic(() => import("./ProductCategory"), {
//    ssr: false,
//    loading: () => <div style={{ height: "270px" }} />
// });

interface IProps {
   isMobile: boolean;
   shopCategories: IMenuItem[];
   products: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks: IUserBookmark[];
   };
   productsPropose: {
      paginate: Paginate<Product, IProduct>;
      userBookmarks: IUserBookmark[];
   };
   flashSaleWithProduct: {
      ["paginate"]: Paginate<Product, IProduct>;
      ["flashSales"]: FlashSale;
   };
}
export default function ECommerceOverall(props: IProps) {
   const [state, setCurrentState] = useState<{
      isActiveFlashSale?: boolean;
   }>({});
   const [userBookmarks, setUserBookmarks] = useState([...(props.products.userBookmarks ?? []), ...(props.productsPropose.userBookmarks ?? [])]);

   function setState(newState) {
      setCurrentState((prev) => ({
         ...prev,
         ...newState,
      }));
   }

   useEffect(() => {
      setUserBookmarks([...(props.products.userBookmarks ?? []), ...(props.productsPropose.userBookmarks ?? [])]);
   }, [props.products, props.productsPropose]);

   useEffect(() => {
      setState({
         isActiveFlashSale: isStartFlashSale(props.flashSaleWithProduct.flashSales),
      });
   }, [props.flashSaleWithProduct.flashSales]);

   const isStartFlashSale = (flashSales) => {
      if (!flashSales) return;
      const currentTime = new Date().getTime();
      const startFlashsaleNum = new Date(flashSales?.info?.startDateTime || 0).getTime();
      const endFlashsaleNum = new Date(flashSales?.info?.endDateTime || 0).getTime();
      const isStartFlashSale = currentTime >= startFlashsaleNum && currentTime <= endFlashsaleNum;
      return isStartFlashSale;
   };

   const handleEndCountdownFlashSales = () => {
      setState({
         isActiveFlashSale: isStartFlashSale(props.flashSaleWithProduct.flashSales),
      });
   };

   const handleChangeUserBookmark = (action: "create" | "delete", userBookmark: IUserBookmark) => {
      if (action === "create") {
         setUserBookmarks([...userBookmarks, userBookmark]);
      } else {
         const newUserBookmarks = userBookmarks.filter((bookmark) => bookmark._id !== userBookmark._id);
         setUserBookmarks(newUserBookmarks);
      }
   };

   return (
      <>
         {/* flash sale */}
         <div className="block">
            {state.isActiveFlashSale === undefined ? (
               <div className="flash-sale-init"></div>
            ) : (
               state.isActiveFlashSale &&
               props.flashSaleWithProduct?.flashSales && (
                  <FlashSaleSection
                     isMobile={props.isMobile}
                     paginateFlashSale={props.flashSaleWithProduct.paginate}
                     flashSale={props.flashSaleWithProduct?.flashSales}
                     onEndCountdownFlashSales={handleEndCountdownFlashSales}
                  />
               )
            )}
         </div>
         {/* Category */}
         <div className="block">
            <div className="block-sub ">
               <div className="title">Cửa hàng nội thất</div>
            </div>
            <div className="sub-title">Thỏa sức lựa chọn sản phẩm nội thất bạn yêu thích</div>
            <ProductCategory categories={props.shopCategories} />
         </div>
         {/* Best seller */}
         <div className="block">
            <div className="block-sub ">
               <div className="title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                     <path
                        d="M16.0684 2.66669L14.2417 6.40002C12.9365 9.06715 11.1871 11.4928 9.0684 13.5734L8.8284 13.8C7.46769 15.121 6.69022 16.9303 6.6684 18.8267V19.0667C6.63236 22.7802 8.84521 26.1469 12.2684 27.5867L12.6151 27.7334C14.8602 28.687 17.3966 28.687 19.6417 27.7334H19.7217C23.1705 26.2349 25.3833 22.8133 25.3351 19.0534V13.2667C24.1858 15.8914 22.0986 17.9929 19.4817 19.16C19.4817 19.16 19.4817 19.16 19.4017 19.16C19.3217 19.16 18.3884 19.5467 17.9884 19.16C17.6311 18.7985 17.5969 18.2283 17.9084 17.8267L18.0017 17.76H18.0684C21.1294 15.4333 21.8431 11.1223 19.6951 7.93335C17.9617 5.29335 16.0684 2.66669 16.0684 2.66669Z"
                        fill="#FF6347"
                     />
                  </svg>
                  Sản phẩm bán chạy
               </div>{" "}
               <Link title="" href="/shop/san-pham-ban-chay" className="view-all ">
                  <span className="viewall-text">Xem tất cả </span>
                  <span className="next-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 6L15 12L9 18" stroke="#FF6347" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </span>
               </Link>
            </div>
            <BestSeller products={props.products} userBookmarks={userBookmarks} handleChangeUserBookmark={handleChangeUserBookmark} />
         </div>
         {/* Recommend for you */}
         <div className="block">
            <div className="block-sub ">
               <div className="title">Gợi ý cho bạn</div>{" "}
               <Link title="" href="/shop" className="view-all ">
                  <span className="viewall-text">Xem tất cả </span>
                  <span className="next-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 6L15 12L9 18" stroke="#FF6347" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </span>
               </Link>
            </div>
            <BestSeller title={"propose"} products={props.productsPropose} userBookmarks={userBookmarks} handleChangeUserBookmark={handleChangeUserBookmark} />
         </div>
      </>
   );
}
