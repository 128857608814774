import Link from "next/link";
import React from "react";
import Carousel from "react-slick";

export default function MainCategory() {
   const [menus, setMenus] = React.useState([
      {
         title: "Cộng đồng",
         icon: "/assets/img/menu/feed.png",
         iconLg: "/assets/img/menu/feed.png",
         path: "/feed",
      },
      {
         title: "Cửa hàng",
         icon: "/assets/img/menu/shop.png",
         iconLg: "/assets/img/menu/shop.png",
         path: "/shop",
      },

      {
         title: "Nhận tư vấn",
         icon: "/assets/img/menu/advise.png",
         iconLg: "/assets/img/menu/advise.png",
         path: "/cau-hoi-khao-sat",
      },
      {
         title: "Dự toán",
         icon: "/assets/img/menu/calculate.png",
         iconLg: "/assets/img/menu/calculate.png",
         path: "/bao-gia",
      },
      {
         title: "Dự án hoàn thiện",
         icon: "/assets/img/menu/project-finish.png",
         iconLg: "/assets/img/menu/project-finish.png",
         path: "/thu-vien-thiet-ke",
      },
      {
         title: "Công ty nội thất",
         icon: "/assets/img/menu/interior-company.png",
         iconLg: "/assets/img/menu/interior-company.png",
         path: "/cac-cong-ty-noi-that",
      },
      {
         title: "Thư viện ảnh",
         icon: "/assets/img/menu/library.png",
         iconLg: "/assets/img/menu/library.png",
         path: "/thu-vien-anh",
      },
      {
         title: "Hỏi đáp",
         icon: "/assets/img/menu/feed-ask.png",
         iconLg: "/assets/img/menu/feed-ask.png",
         path: "/hoi-dap",
      },
   ]);

   return (
      <div className="main-category">
         <div className="container">
            <div className="row gx-1 gy-3 d-none d-md-flex">
               {menus.map((item, index) => (
                  <div className="col-3 col-md" key={index}>
                     <Link className="menu-item" href={item.path}>
                        <div className="image">
                           <picture>
                              <source media="(min-width: 768px)" srcSet={item.iconLg ?? item.icon} />
                              <source media="(max-width: 767px)" srcSet={item.icon ?? ""} />
                              <img title={item.title ?? ""} alt={item.title ?? ""} src={item.icon ?? ""} />
                           </picture>
                           <div className="cover"></div>
                        </div>
                        <span>{item.title}</span>
                     </Link>
                  </div>
               ))}
            </div>
            <div className="carowsel-mb d-block d-md-none">
               <Carousel dots arrows={false} slidesToShow={4.5} slidesToScroll={4.5} infinite={false}>
                  {menus.map((item, index) => (
                     <div className="col-3 col-md" key={index}>
                        <Link className="menu-item" href={item.path}>
                           <div className="image">
                              <picture>
                                 <source media="(min-width: 768px)" srcSet={item.iconLg ?? item.icon} />
                                 <source media="(max-width: 767px)" srcSet={item.icon ?? ""} />
                                 <img title={item.title ?? ""} alt={item.title ?? ""} src={item.icon ?? ""} />
                              </picture>
                              <div className="cover"></div>
                           </div>

                           <span>{item.title}</span>
                        </Link>
                     </div>
                  ))}
               </Carousel>
            </div>
         </div>
      </div>
   );
}
