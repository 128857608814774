import React from "react";
import Link from "@Components/Link";

export default function HornorBrand() {
   const [menus, setMenus] = React.useState([
      {
         title: "",
         icon: "/assets/img/home/lotteaccelerator-mb.png",
         iconLg: "/assets/img/home/lotteaccelerator.png",
         path: "",
      },
      {
         title: "",
         icon: "/assets/img/home/technasia-mb.png",
         iconLg: "/assets/img/home/technasia.png",
         path: "",
      },

      {
         title: "",
         icon: "/assets/img/home/google-mb.png",
         iconLg: "/assets/img/home/google.png",
         path: "",
      },
   ]);

   return (
      <div className="hornor-brand">
         <div className="container">
            <div className="section-title">Space T tự hào được vinh danh tại:</div>
            <div className="row gx-2 gy-3 gx-md-3">
               {menus.map((item, index) => (
                  <div className="col-4 " key={index}>
                     <Link className="brand-item " href={item.path}>
                        <div className="image">
                           <picture>
                              <source media="(min-width: 768px)" srcSet={item.iconLg ?? item.icon} />
                              <source media="(max-width: 767px)" srcSet={item.icon ?? ""} />
                              <img title={item.title ?? ""} alt={item.title ?? ""} src={item.icon ?? ""} />
                           </picture>
                           <div className="cover"></div>
                        </div>

                        {/* <span>{item.title}</span> */}
                     </Link>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
}
