import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import React from "react";
import Slider, { Settings } from "react-slick";
import Ava1 from "../images/avatar1.png";
import Ava2 from "../images/avatar2.png";
import Ava3 from "../images/avatar3.png";

interface ICustomerReview {
   imgSrcBefore: string;
   avatar: string;
   name: string;
   address: string;
   rating: number;
   review: any;
   des?: string;
   date: string;
}
interface IState {
   customerReview: ICustomerReview[];
   activeItem: number;
}

export default class Review extends React.Component<any, IState> {
   constructor(props: any) {
      super(props);
      this.state = {
         activeItem: 0,
         customerReview: [
            {
               imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/construction1_v1.webp",
               avatar: Ava2.src,
               name: "Trần Thị Ngọc Nhi",
               address: "Eco Green Saigon",
               rating: 5,
               review: `Vì hiếm khi cần cải tạo hay thiết kế nội thất cho căn hộ nên chi phí luôn là mối bận tâm lớn nhất của mình. Thật may, khi biết đến Space T
                     mình đã được tư vấn rất kỹ về mức chi phí phải bỏ ra, nhà thầu được kết nối với mình cũng vô cùng uy tín, làm đúng nhu cầu và chi tiết, nhờ
                     vậy mà mình đã hoàn thiện được nội thất cho căn phòng mơ ước của mình với chi phí không thể hợp lý hơn`,
               date: "Hoàn thiện tháng 07/2022",
            },
            {
               imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/construction2_v1.webp",
               avatar: Ava1.src,
               name: "Nguyễn Đỗ Thu Uyên",
               address: "Vinhomes Grand Park",
               rating: 4.5,
               review: `Là một phụ nữ, tôi không quen với việc phải đi tìm nhà thầu hay quy trình làm việc với nhà thầu. Rất may khi tìm được Space T, tôi đã tiết
                     kiệm được thời gian và công sức rất nhiều. Space T giúp tôi kết nối với nhà thầu uy tín và tư vấn cực kì tận tâm. Nhất định sẽ giới thiệu
                     Space T cho bạn bè xung quanh.`,
               date: "Hoàn thiện tháng 02/2023",
            },
            {
               imgSrcBefore: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/construction3_v1.webp",
               avatar: Ava3.src,
               name: "Đỗ Anh Quân",
               address: "WestGate Bình Chánh",
               rating: 4.8,
               review: `SpaceT đã giúp tôi thiết kế và thi công lại toàn bộ căn nhà với chi phí vô cùng hợp lý. Điều khiến tôi hài lòng nhất là dịch vụ tư vấn miễn
                     phí nhưng cực kì tận tâm, giúp tôi đưa ra những giải pháp phù hợp với đúng với nhu cầu của mình, luôn hỗ trợ 24/7 mỗi khi tôi cần.`,
               date: "Hoàn thiện tháng 03/2024",
            },
         ],
      };
   }
   handleStepChange = (item: number) => {
      this.setState({ activeItem: item });
   };
   render() {
      const settings: Settings = {
         dots: false,
         arrows: false,
         infinite: false,
         autoplay: false,
         cssEase: "linear",
         slidesToShow: 3,
         slidesToScroll: 3,
         nextArrow: <ArrowForwardIos />,
         prevArrow: <ArrowBackIosNew />,
         responsive: [
            {
               breakpoint: 1024,
               settings: {
                  slidesToShow: 2.1,
                  slidesToScroll: 2.1,
                  dots: true,
               },
            },
            {
               breakpoint: 600,
               settings: {
                  slidesToShow: 1.1,
                  slidesToScroll: 1.1,
                  dots: true,
               },
            },
         ],
      };
      return (
         <section className="review block">
            <div className="block-sub ">
               <div className="title">Khách hàng nói gì về Space T</div>{" "}
            </div>
            <div className="slider-wrap">
               <Slider {...settings}>
                  {this.state.customerReview.map((item, i) => {
                     return (
                        <div className="slider-item" key={i}>
                           <div className="img position-relative ">
                              <div className="wrap-avatar">
                                 <img src={item.avatar} alt="" />
                              </div>
                              <img src={item.imgSrcBefore} alt="Phản hồi từ khách hàng" className="user-img" style={{ width: "100%" }} />
                              <div className="cover"></div>
                           </div>
                           <div className="review-detail">
                              <div className="review-detail__title">{item.name}</div>
                              <div className="review-detail__sub-title">{item.address}</div>
                              <div className="review-detail__rating">
                                 <img src="/assets/img/cover/ful-star.svg" alt="#" />
                                 <span>{item.rating}/5</span>
                              </div>
                              <div className="review-detail__des">
                                 {/* <sup>
                                       <img src="/assets/img/icon/left-quote.svg" alt="" />
                                    </sup> */}
                                 {item.review}
                                 {/* <sup>
                                       <img src="/assets/img/icon/left-quote.svg" alt="" style={{ transform: "rotate(180deg)" }} />
                                    </sup> */}
                              </div>
                              <p className="review-detail__date">{item.date}</p>
                           </div>
                        </div>
                     );
                  })}
               </Slider>
            </div>
         </section>
      );
      // return (
      //     <section className="slider-review bg-pink mb-3 mb-lg-4">
      //         <div className="container">
      //             <h2 className="title">Phản hồi từ khách hàng</h2>
      //             <div className="slider-wrap">
      //                 <Slider {...settings}>
      //                     {
      //                         this.state.customerReview.map((item, i) => {
      //                             return (
      //                                 <div className="slider-item" key={i}>
      //                                     <div className="row gx-0">
      //                                         <div className="col-md-6 col-12 position-relative">
      //                                             <Image width={550} height={330} src={item.imgSrc} alt="Phản hồi từ khách hàng" />
      //                                             <div className="cover"></div>
      //                                         </div>
      //                                         <div className="col-md-6 col-12">
      //                                             <div className="slider-item-customer">
      //                                                 <h3 className=" title">{item.name}</h3>
      //                                                 <div className="rating"><img src="/assets/img/cover/full-star.webp" alt="#" /><span>{item.rating}/5</span></div>
      //                                                 <p className="des"><sup><img src="/assets/img/icon/left-quote.svg" alt="Space T" /></sup> {item.review} <sup><img src="/assets/img/icon/left-quote.svg" alt="Space T" style={{ transform: "rotate(180deg)" }} /></sup></p>
      //                                                 <p className="sub-des">{item.des}</p>
      //                                             </div>
      //                                         </div>
      //                                     </div>
      //                                 </div>
      //                             )
      //                         })
      //                     }
      //                 </Slider>
      //             </div>
      //         </div>
      //     </section>

      // )
   }
}
