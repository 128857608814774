import * as types from "./types";
import { Model, ModelError, TPaginate } from "@Models/model";
import GeneralMediasBySpace, { IGeneralMediasBySpace } from "@Models/generalMediasBySpace.model";

export const fetchHomeGalleries = (slug) => {
   return async (dispatch) => {
      const res = await Model.fetch<TPaginate<IGeneralMediasBySpace>>({
         method: "GET",
         endPoint: "homepage/galleries",
         headers: {
            ["slug"]: slug
         }
      })

      if (res instanceof ModelError) {
         return res;
      }

      const payload = res.data?.docs?.map((item) => new GeneralMediasBySpace(item))

      dispatch({
         type: types.SET_HOME_GALLERIES,
         payload: { data: payload, slug }
      })
   }
};
