import { FunctionComponent, useState } from "react";
import Link from "@Components/Link";
import classNames from "classnames";
import LayoutContainer from "../../../components/Container";
import Brand from "../../../models/brand.model";
import MenuItem from "models/menuItem.model";
import { Paginate } from "models/model";
import Partner, { IPartner } from "models/partner.model";

interface ContentEndProps {
   brands: Brand[];
   menulv2: MenuItem[];
   partners: Paginate<Partner, IPartner>;
}
const PARTNER = [
   { name: "Win Design", slug: "/cac-cong-ty-noi-that/win-design" },
   { name: "Trung Nam Decor", slug: "/cac-cong-ty-noi-that/trung-nam-decor" },
   { name: "Sunshine CAF", slug: "/cac-cong-ty-noi-that/sunshine-caf" },
   { name: "SF HOME", slug: "/cac-cong-ty-noi-that/sf-home" },
   { name: "Nội Thất Quang Hải", slug: "/cac-cong-ty-noi-that/noi-that-quang-hai" },
   { name: "M&S Interior Design", slug: "/cac-cong-ty-noi-that/m-va-s-interior-design" },
   { name: "IF DESIGN", slug: "/cac-cong-ty-noi-that/if-design" },
   { name: "Hải Đăng", slug: "/cac-cong-ty-noi-that/hai-dang" },
   { name: "D.C House ", slug: "/cac-cong-ty-noi-that/DC-House" },
   { name: "Mộc Đức", slug: "/cac-cong-ty-noi-that/moc-duc" },
   { name: "H+ Architect", slug: "/cac-cong-ty-noi-that/H-Architect" },
   { name: "CT CONCEPT", slug: "/cac-cong-ty-noi-that/ct-concept" },
   { name: "Đoàn Gia Phát", slug: "/cac-cong-ty-noi-that/doan-gia-phat" },
   { name: "T&T", slug: "/cac-cong-ty-noi-that/T&T" },
   { name: "D.CONCEPT", slug: "/cac-cong-ty-noi-that/dconcept" },
   //    { name: "Win Design", slug: "/cac-cong-ty-noi-that/win-design" },
   //    { name: "Trung Nam Decor", slug: "/cac-cong-ty-noi-that/trung-nam-decor" },
   //    { name: "Sunshine CAF", slug: "/cac-cong-ty-noi-that/sunshine-caf" },
   //    { name: "SF HOME", slug: "/cac-cong-ty-noi-that/sf-home" },
   //    { name: "Nội Thất Quang Hải", slug: "/cac-cong-ty-noi-that/noi-that-quang-hai" },
   //    { name: "M&S Interior Design", slug: "/cac-cong-ty-noi-that/m-va-s-interior-design" },
   //    { name: "IF DESIGN", slug: "/cac-cong-ty-noi-that/if-design" },
   //    { name: "Hải Đăng", slug: "/cac-cong-ty-noi-that/hai-dang" },
   //    { name: "D.C House ", slug: "/cac-cong-ty-noi-that/DC-House" },
   //    { name: "Mộc Đức", slug: "/cac-cong-ty-noi-that/moc-duc" },
   //    { name: "H+ Architect", slug: "/cac-cong-ty-noi-that/H-Architect" },
   //    { name: "CT CONCEPT", slug: "/cac-cong-ty-noi-that/ct-concept" },
   //    { name: "Đoàn Gia Phát", slug: "/cac-cong-ty-noi-that/doan-gia-phat" },
   //    { name: "T&T", slug: "/cac-cong-ty-noi-that/T&T" },
   //    { name: "D.CONCEPT", slug: "/cac-cong-ty-noi-that/dconcept" },
   //    { name: "Tre Nghệ", slug: "/cac-cong-ty-noi-that/tre-nghe" },
   //    { name: "NAVI DECOR", slug: "/cac-cong-ty-noi-that/noi-that-navi-decor" },
   //    { name: "Nhà Việt", slug: "/cac-cong-ty-noi-that/nha-viet" },
   //    { name: "Decor 2309", slug: "/cac-cong-ty-noi-that/decor-2309" },
   //    { name: "Thành Gia Bảo", slug: "/cac-cong-ty-noi-that/thanh-gia-bao" },
   //    { name: "SKN", slug: "/cac-cong-ty-noi-that/skn" },
   //    { name: "Cộng Design", slug: "/cac-cong-ty-noi-that/cong-design" },
   //    { name: "FATTO HOME", slug: "/cac-cong-ty-noi-that/fatto-home" },
   //    { name: "HOME TAT", slug: "/cac-cong-ty-noi-that/home-tat" },
   //    { name: "Huy Bảo Tín", slug: "/cac-cong-ty-noi-that/huy-bao-tin" },
   //    { name: "LE ART", slug: "/cac-cong-ty-noi-that/le-art" },
   //    { name: "Nguyên Thanh", slug: "/cac-cong-ty-noi-that/nguyen-thanh" },
   //    { name: "Nội Thất Vạn Huệ", slug: "/cac-cong-ty-noi-that/noi-that-van-hue" },
   //    { name: "PB Decor", slug: "/cac-cong-ty-noi-that/pb-decor" },
   //    { name: "THE TIME DECOR", slug: "/cac-cong-ty-noi-that/the-time-decor" },
   //    { name: "Tony Decor", slug: "/cac-cong-ty-noi-that/tony-decor" },
   //    { name: "Tâm Đan Furniture", slug: "/cac-cong-ty-noi-that/tam-dan-furniture" }
];
// const SHOP = [
//    { name: "Nội thất", slug: "/shop/noi-that" },
//    { name: "Đồ trang trí", slug: "/shop/do-trang-tri" },
//    { name: "Đồ gia dụng", slug: "/shop/do-gia-dung" },
//    { name: "Lưu trữ", slug: "/shop/luu-tru" },
//    { name: "Chăn ga gối đệm", slug: "/shop/chan-ga-goi-dem" },
//    { name: "Đèn", slug: "/shop/den" },
//    { name: "Thảm", slug: "/shop/tham" },
//    { name: "Office", slug: "/shop/office" },
//    { name: "Combo nội thất", slug: "/shop/combo-noi-that" }
// ];
const KEYWORDS = [
   { name: "thiết kế nội thất", slug: "/blog/thiet-ke-noi-that" },
   { name: "thi công nội thất", slug: "/blog/thi-cong-noi-that" },
   { name: "mẫu phòng ngủ đẹp", slug: "/thu-vien-anh/mau-phong-ngu" },
   { name: "mẫu phòng khách đẹp", slug: "/thu-vien-anh/mau-phong-khach" },
   { name: "mẫu phòng ăn", slug: "/thu-vien-anh/mau-phong-an" },
   { name: "mẫu phòng làm việc", slug: "/thu-vien-anh/mau-phong-lam-viec" },
   { name: "mẫu phòng cho bé", slug: "/thu-vien-anh/mau-phong-cho-be" },
   { name: "mẫu phòng tắm", slug: "/thu-vien-anh/mau-phong-tam" },
   { name: "mẫu phòng bếp", slug: "/thu-vien-anh/mau-phong-bep" },
   { name: "mẫu lối vào", slug: "/thu-vien-anh/mau-loi-vao" },
   { name: "mẫu lô gia", slug: "/thu-vien-anh/mau-lo-gia" },
   { name: "thiết kế nhà online", slug: "/thiet-ke-2d-3d" },
   { name: "chung cư 3 phòng ngủ", slug: "/blog/nguon-cam-hung/top-15-y-tuong-thiet-ke-noi-that-chung-cu-3-phong-ngu-doc-dao-nhat-2023" },
   { name: "chung cư 2 phòng ngủ", slug: "/blog/nguon-cam-hung/15-y-tuong-thiet-ke-noi-that-chung-cu-2-phong-ngu-moi-la-theo-xu-huong-2023" },
   { name: "mẫu nhà phố đẹp", slug: "/blog/nguon-cam-hung/top-29-mau-nha-pho-dep-hien-dai-duoc-tim-kiem-nhieu-nhat-2023" },
   {
      name: "mẫu nhà mái Thái",
      slug: "/blog/kien-thuc-noi-that/nha-mai-thai-thong-tin-chi-tiet-chi-phi-xay-dung-nha-mai-thai-va-29-mau-nha-mai-thai-dep-me-ly",
   },
   { name: "mẫu nhà mái Nhật", slug: "/blog/nguon-cam-hung/top-29-mau-nha-mai-nhat-dep-kho-cuong-cho-gia-chu-2023" },
   { name: "mẫu nhà cấp 4 đẹp", slug: "/blog/nguon-cam-hung/top-29-mau-nha-cap-4-dep-me-ly-van-nguoi-me" },
   { name: "mẫu nhà ống đẹp", slug: "/blog/nguon-cam-hung/top-19-mau-thiet-ke-noi-that-nha-ong-2023-dep-me-ly" },
   { name: "công ty nội thất", slug: "/cac-cong-ty-noi-that" },
   { name: "báo giá thi công", slug: "/bao-gia" },
   { name: "chi phí thiết kế nội thất", slug: "/blog/kien-thuc-noi-that/chi-phi-thiet-ke-noi-that-chi-tiet-can-biet-khi-lam-nha" },
   { name: "phong cách retro", slug: "/blog/phong-cach-noi-that/phong-cach-noi-that-retro-khong-gian-hien-dai-mang-dau-an-hoai-co" },
   { name: "phong cách wabi sabi", slug: "/blog/phong-cach-noi-that/phong-cach-wabi-sabi-net-tham-my-cua-su-khong-hoan-hao" },
   { name: "phong cách indochine", slug: "/blog/phong-cach-noi-that/phong-cach-indochine-dong-duong-la-gi" },
   { name: "phong cách scandinavian", slug: "/blog/phong-cach-noi-that/scandinavian-phong-cach-noi-that-thinh-hanh" },
   { name: "phong cách tối giản", slug: "/blog/phong-cach-noi-that/phong-cach-thiet-ke-noi-that-minimalist" },
   { name: "vật liệu gỗ", slug: "/blog/kien-thuc-noi-that/go-cong-nghiep-dac-diem-va-bang-gia-cac-loai-go-cong-nghiep-pho-bien-hien-nay" },
   { name: "phong cách hiện đại", slug: "/blog/phong-cach-noi-that/phong-cach-thiet-ke-noi-that-hien-dai" },
];

const ContentEnd: FunctionComponent<ContentEndProps> = (props) => {
   const [loadMore, setLoadMore] = useState<boolean>(true);
   const loadMoreContent = () => {
      setLoadMore(!loadMore);
   };
   return (
      <div className="block mt-md-5">
         {/* <div className="block-sub ">
            <div className="title">Đối tác của Space T</div>
         </div> */}
         <div className="content_end_page">
            <div className="content_item">
               <h3 className="content_title">SPACE T - NỀN TẢNG NỘI THẤT</h3>
               <div className="content_description">
                  Space T là công ty công nghệ hoạt động trong lĩnh vực nội thất, xây dựng. Space T mang đến giải pháp hoàn thiện toàn diện cho công trình của
                  bạn: tìm kiếm ý tưởng, kết nối nhà thầu, nhận tư vấn, báo giá thiết kế & thi công, mua sắm nội thất, đồ trang trí, gia dụng. Chúng tôi ra đời
                  với sứ mệnh giúp các gia chủ Việt dễ dàng, nhanh chóng hoàn thiện ngôi nhà trong mơ.
                  {loadMore && (
                     <span className="btn_see_more see_more" onClick={loadMoreContent}>
                        Xem thêm
                     </span>
                  )}
               </div>
            </div>
            <div className={classNames(["more_content"], [loadMore ? ["see_more"] : "see_less"])}>
               <div className="content_item">
                  <h3 className="content_title">TẢI ỨNG DỤNG SPACE T</h3>
                  <div className="content_description">
                     Ứng dụng Space T cho phép bạn trải nghiệm đầy đủ những tính năng tiện lợi dành cho người yêu thích nội thất, chuyên gia nội thất và chủ nhà
                     có nhu cầu hoàn thiện nội thất:
                  </div>
                  <ul>
                     <li>
                        <div className="content_description">
                           Xem, tương tác, lưu trữ và chia sẻ ý tưởng, câu chuyện, hình ảnh, kiến thức từ cộng đồng người yêu nội thất và chuyên gia nội thất.
                        </div>
                     </li>
                     <li>
                        <div className="content_description">
                           Tham khảo thông tin công trình thi công thực tế, mẫu thiết kế, dễ dàng gửi yêu cầu kết nối, nhận tư vấn và báo giá làm nội thất nhanh
                           chóng, miễn phí.
                        </div>
                     </li>
                     <li>
                        <div className="content_description">
                           Mua sắm đồ nội thất, trang trí, gia dụng online tiện lợi với thao tác đơn giản cùng nhiều ưu đãi hấp dẫn.
                        </div>
                     </li>
                  </ul>
               </div>

               <div className="content_item">
                  <h3 className="content_title">SPACE T FEED</h3>
                  <div className="content_description">
                     <a className="fw-bold" href="/feed" title="Space T Feed">
                        Space T Feed
                     </a>{" "}
                     là mạng xã hội Facebook thu nhỏ, là cộng đồng dành cho tín đồ yêu thích nội thất, gia chủ, chủ đầu tư và chuyên gia nội thất với đa dạng
                     nội dung, dễ dàng tìm hiểu, chia sẻ kiến thức, kinh nghiệm, cảm hứng nội thất.
                  </div>
               </div>
               <div className="content_item">
                  <h3 className="content_title">MUA SẮM NỘI THẤT CHÍNH HÃNG</h3>
                  <div className="content_description">
                     Mua sắm online hơn 8000+ đồ nội thất, trang trí, gia dụng chính hãng đến từ 50+ thương hiệu với nhiều ưu đãi hấp dẫn. Dễ dàng tìm kiếm và
                     lựa chọn những món đồ ưng ý cho mọi không gian nội thất. Ngoài ra, bạn có thể đăng ký tài khoản, tham gia đăng bài Social feed, review sản
                     phẩm,... để nhận
                     <Link title="Space T Xu" href="/chinh-sach/chinh-sach-diem">
                        {" "}
                        Space T Xu
                     </Link>{" "}
                     và sử dụng để giảm giá trực tiếp cho các đơn hàng mua sắm nội thất. <br />
                     <div>
                        {props.menulv2 &&
                           props.menulv2?.map((e, i: number) => {
                              return (
                                 <Link href={`/shop/${e.info.slug}`} title={e.info.title} key={i} className="mb-2">
                                    {e.info.title}
                                    {i < props.menulv2?.length - 1 && <span style={{ margin: "0 4px" }}>|</span>}
                                 </Link>
                              );
                           })}
                     </div>
                  </div>
               </div>
               <div className="content_item">
                  <h3 className="content_title">TOP THƯƠNG HIỆU NỔI BẬT</h3>
                  <div className="content_description">
                     {props.brands?.map((e: Brand, i: number) => {
                        return (
                           <Link title={e.info.name ?? ""} href={`/shop/thuong-hieu/${e.info.slug}`} key={i} className="mb-2">
                              {e.info.name}
                              {i < props.brands?.length - 1 && <span style={{ margin: "0 4px" }}>|</span>}
                           </Link>
                        );
                     })}
                  </div>
               </div>
               <div className="content_item">
                  <h3 className="content_title">THIẾT KẾ THI CÔNG</h3>
                  <div className="content_description">
                     Space T - Nền tảng nội thất kết nối chủ nhà với công ty nội thất uy tín. Bạn có thể tham khảo chi tiết về{" "}
                     <a href="quy-trinh-ket-noi">quy trình kết nối</a> chủ nhà với công ty nội thất qua nền tảng nội thất Space T.
                  </div>
                  <div className="content_description">
                     <a className="fw-bold" href="/thu-vien-anh" title="Thư viện ảnh">
                        Thư viện ảnh
                     </a>{" "}
                     - Tổng hợp hình ảnh nội thất đẹp theo các khu vực, không gian phòng trong nhà.
                  </div>
                  <div className="content_description mt-2">
                     <strong>Dự án hoàn thiện</strong> - Tổng hợp{" "}
                     <a href="/thu-vien-thiet-ke" title="Thư viện thiết kế">
                        Thư viện thiết kế{" "}
                     </a>
                     concept nội thất,{" "}
                     <a href="/cong-trinh-thuc-te" title="Công trình thực tế">
                        Công trình thực tế{" "}
                     </a>
                     hoàn thiện và chia sẻ về{" "}
                     <a href="/to-am-cua-toi" title="Tổ ấm của tôi">
                        Tổ ấm của tôi.
                     </a>
                  </div>
                  <div className="content_description mt-2">
                     <a className="fw-bold" href="/bao-gia" title="Tính chi phí">
                        Tính chi phí
                     </a>{" "}
                     - Công cụ tính chi phí làm nội thất cho gia chủ cực tiện lợi và sát với chi phí thực tế.
                  </div>
                  <div className="content_description mt-2">
                     <a className="fw-bold" href="/thiet-ke-2d-3d" title="Thiết kế 3D">
                        Thiết kế 3D
                     </a>{" "}
                     - Công cụ để bạn tự tay thiết kế không gian nội thất 2D/3D online.
                  </div>
                  <div className="content_description mt-2">
                     <a className="fw-bold" href="/blog" title="Kiến thức">
                        Kiến thức
                     </a>{" "}
                     - Thư viện bài viết kiến thức, cảm hứng thiết kế và mẹo hay về nội thất - xây dựng.
                  </div>
                  <div className="content_description mt-2">
                     <a className="fw-bold" href="/cac-cong-ty-noi-that" title="Danh sách nhà thầu">
                        Danh sách nhà thầu
                     </a>{" "}
                     - Danh sách đơn vị nội thất uy tín đã được Space T kiểm định về chất lượng.
                  </div>
               </div>

               <div className="content_item">
                  <h3 className="content_title">DANH SÁCH NHÀ THẦU</h3>
                  <div className="content_description">
                     {props.partners?.docs?.map((e, i: number) => {
                        return (
                           <Link title={e.info.name || ""} href={`/cac-cong-ty-noi-that/${e.info.slug}`} key={i} className="mb-2">
                              {e.info.name}
                              {i < props.partners?.docs.length - 1 && <span style={{ margin: "0 4px" }}>|</span>}
                           </Link>
                        );
                     })}
                  </div>
               </div>

               <div className="content_item">
                  <h3 className="content_title">TOP TỪ KHÓA XU HƯỚNG</h3>
                  <div className="content_description">
                     {KEYWORDS.map((e, i: number) => {
                        return (
                           <a title={e.name ?? ""} href={e.slug} key={i} className="mb-2">
                              {e.name}
                              {i < KEYWORDS.length - 1 && <span style={{ margin: "0 4px" }}>|</span>}
                           </a>
                        );
                     })}
                     {!loadMore && (
                        <span className="btn_hide_see_more" onClick={loadMoreContent}>
                           <span> </span>Ẩn bớt
                        </span>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default ContentEnd;
