import Link from "next/link";
// import dynamic from "next/dynamic";
import { Paginate } from "../../../models/model";
// import { IPartner } from "../../../models/partner.model";
import { IUserBookmark } from "../../../models/userBookmark.model";
import ActualWork, { IActualWork } from "../../../models/actualWork.model";

// import Partners from "./Partner";
// import Procedure from "./Procedure";
import Review from "./Review";
import ImageBySpaces from "./ImageBySpace";
// import DesignLibrary from "./DesignLibrary";
// import Calculator from "pages/quy-trinh-ket-noi/components/calculator/Calculator";

// const Calculator = dynamic(() => import('../../quy-trinh-ket-noi/components/calculator/Calculator'), {
//    ssr: false,
//    loading: () => <div style={{ height: '250px' }} />,
//  })

//  const Review = dynamic(() => import('./Review'), {
//    ssr: false,
//    loading: () => <div style={{ height: '430px' }} />,
//  })
//  const ImageBySpaces = dynamic(() => import('./ImageBySpace'), {
//    // ssr: true,
//    loading: () => <div style={{ height: '250px' }} />,
//  })
//  const DesignLibrary = dynamic(() => import('./DesignLibrary'), {
//    // ssr: true,
//    loading: () => <div style={{ height: '250px' }} />,
//  })

interface IProps {
   dataHomepage: {
      actualWorks: Paginate<ActualWork, IActualWork>;
      designLibraries: Paginate<ActualWork, IActualWork>;
      designLibraryUserBookmarks: IUserBookmark[];
   };
   keywordGroupsByType?: any;
}
export default function ConstructionOverall(props: IProps) {
   return (
      <>
         <div className="block">
            <div className="block-sub ">
               <div className="title">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                     <path
                        d="M16.0684 2.66669L14.2417 6.40002C12.9365 9.06715 11.1871 11.4928 9.0684 13.5734L8.8284 13.8C7.46769 15.121 6.69022 16.9303 6.6684 18.8267V19.0667C6.63236 22.7802 8.84521 26.1469 12.2684 27.5867L12.6151 27.7334C14.8602 28.687 17.3966 28.687 19.6417 27.7334H19.7217C23.1705 26.2349 25.3833 22.8133 25.3351 19.0534V13.2667C24.1858 15.8914 22.0986 17.9929 19.4817 19.16C19.4817 19.16 19.4817 19.16 19.4017 19.16C19.3217 19.16 18.3884 19.5467 17.9884 19.16C17.6311 18.7985 17.5969 18.2283 17.9084 17.8267L18.0017 17.76H18.0684C21.1294 15.4333 21.8431 11.1223 19.6951 7.93335C17.9617 5.29335 16.0684 2.66669 16.0684 2.66669Z"
                        fill="#FF6347"
                     />
                  </svg> */}
                  Hình ảnh nội thất
               </div>{" "}
               <Link title="" href="/thu-vien-anh" className="view-all">
                  <span className="viewall-text">Xem tất cả </span>
                  <span className="next-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 6L15 12L9 18" stroke="#FF6347" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </span>
               </Link>
            </div>
            <div className="sub-title">Xem qua các ý tưởng và liên hệ Space T để tư vấn nhé</div>
            <ImageBySpaces keywordGroupsByType={props.keywordGroupsByType} />
         </div>
         <Review />
         {/* <div className="block">
            <div className="block-sub ">
               <div className="title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                     <path
                        d="M16.0684 2.66669L14.2417 6.40002C12.9365 9.06715 11.1871 11.4928 9.0684 13.5734L8.8284 13.8C7.46769 15.121 6.69022 16.9303 6.6684 18.8267V19.0667C6.63236 22.7802 8.84521 26.1469 12.2684 27.5867L12.6151 27.7334C14.8602 28.687 17.3966 28.687 19.6417 27.7334H19.7217C23.1705 26.2349 25.3833 22.8133 25.3351 19.0534V13.2667C24.1858 15.8914 22.0986 17.9929 19.4817 19.16C19.4817 19.16 19.4817 19.16 19.4017 19.16C19.3217 19.16 18.3884 19.5467 17.9884 19.16C17.6311 18.7985 17.5969 18.2283 17.9084 17.8267L18.0017 17.76H18.0684C21.1294 15.4333 21.8431 11.1223 19.6951 7.93335C17.9617 5.29335 16.0684 2.66669 16.0684 2.66669Z"
                        fill="#FF6347"
                     />
                  </svg>
                  Thiết kế thi công
               </div>{" "}
               <Link title="" href="/feed" className="view-all ">
                  <span className="viewall-text">Xem tất cả </span>
                  <span className="next-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 6L15 12L9 18" stroke="#FF6347" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                     </svg> 
                  </span>
               </Link>
            </div>
            <DesignLibrary
               actualWorks={props.dataHomepage.actualWorks}
               designLibrary={props.dataHomepage.designLibraries}
               userBookmarks={props.dataHomepage.designLibraryUserBookmarks}
            />
            <Calculator />
            <Procedure />
            <Partners partners={props?.dataHomepage?.partner} />
         </div> */}
      </>
   );
}
