import React, { useEffect, useRef } from "react";
import Link from "next/link";
import Slider, { Settings } from "react-slick";
import { ImageView } from "../../../components/image";
import Keyword, { EType } from "../../../models/keyword.model";
import { useRouter } from "next/router";
import store, { RootState } from "state";
import { fetchHomeGalleries } from "state/home/actions";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import GeneralMediasBySpace from "@Models/generalMediasBySpace.model";

interface IProps {
   keywordGroupsByType?: {
      [type in EType]: Keyword[];
   };
}

export default function ImageBySpaces({ keywordGroupsByType }: IProps) {
   const sliderRef = useRef<Slider>(null);
   const homeGalleries = useSelector((state: RootState) => state.home.homeGalleries) as { [t: string]: GeneralMediasBySpace[] };
   const router = useRouter();
   // const query = router.query as any;
   const [slug, setSlug] = React.useState<string>(keywordGroupsByType?.space[0]?.info?.slug ?? "");
   useEffect(() => {
      store.dispatch(fetchHomeGalleries(slug));
   }, [slug]);

   const currentGalleries = homeGalleries?.[slug];

   const carouselSettings: Settings = {
      dots: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false,
      responsive: [
         {
            breakpoint: 768,
            settings: {
               slidesToShow: 3.5,
               slidesToScroll: 3,
            },
         },
         {
            breakpoint: 576,
            settings: {
               slidesToShow: 2.5,
               slidesToScroll: 2,
            },
         },
      ],
   };
   return (
      <section className="construction-design">
         <div className="filter-cate">
            {keywordGroupsByType?.space?.map((item, i) => {
               return (
                  <div
                     key={item._id}
                     className={`filter-item    ${slug === item?.info?.slug ? "active" : ""} `}
                     onClick={() => {
                        sliderRef.current?.slickGoTo(0);
                        setSlug(item?.info?.slug);
                     }}
                  >
                     {item?.info?.content}
                  </div>
               );
            })}
         </div>
         {currentGalleries ? (
            <Slider {...carouselSettings} ref={sliderRef}>
               {currentGalleries?.map((item, i) => {
                  return (
                     <div key={item._id} className="slider-item">
                        <Link
                           style={router.query.isApp === "true" ? { pointerEvents: "none" } : {}}
                           title={item?.__post?.info?.title}
                           href={`/thu-vien-anh/${item.url}`}
                           className="text-decoration-none"
                        >
                           <div className="img">
                              <ImageView objectFit="cover" src={item?.$url3And4ColResizeMax} alt="image-by-space" className="type-space-item_image" />
                           </div>
                           <div className="article-item-info ">
                              <div className="truncate-line-2 article-item-info-title">{item?.__post?.info?.title ?? ""}</div>
                           </div>
                        </Link>
                     </div>
                  );
               })}
            </Slider>
         ) : (
            <Slider {...carouselSettings}>
               {[...Array(4)].map((e, i) => (
                  <div className="slider-item" key={i}>
                     <div className="img">
                        <Skeleton animation="wave" width="100%" height={"100%"} />
                     </div>
                  </div>
               ))}
            </Slider>
         )}
      </section>
   );
}
