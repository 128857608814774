import Link from "next/link";
import React from "react";

export default function SpacetParner() {
   const [menus, setMenus] = React.useState([
      {
         title: "",
         icon: "/assets/img/partner/v2/google-mb.png",
         iconLg: "/assets/img/partner/v2/google.png",
         path: "",
      },
      {
         title: "",
         icon: "/assets/img/partner/v2/lotte-mb.png",
         iconLg: "/assets/img/partner/v2/lotte.png",
         path: "",
      },

      {
         title: "",
         icon: "/assets/img/partner/v2/fpt-mb.png",
         iconLg: "/assets/img/partner/v2/fpt.png",
         path: "",
      },
      {
         title: "",
         icon: "/assets/img/partner/v2/hutech-mb.png",
         iconLg: "/assets/img/partner/v2/hutech.png",
         path: "",
      },
      {
         title: "",
         icon: "/assets/img/partner/v2/naver-mb.png",
         iconLg: "/assets/img/partner/v2/naver.png",
         path: "",
      },

      {
         title: "",
         icon: "/assets/img/partner/v2/hana-mb.png",
         iconLg: "/assets/img/partner/v2/hana.png",
         path: "",
      },
   ]);

   return (
      <div className="block">
         <div className="block-sub ">
            <div className="title">Đối tác của Space T</div>
         </div>
         <div className="spacet-partner">
            <div className="row gx-2 gy-2 gy-md-3 gx-md-3">
               {menus.map((item, index) => (
                  <div className="col-6 col-md-4 " key={index}>
                     <div className="partner-item ">
                        <div className="image">
                           <picture>
                              <source media="(min-width: 768px)" srcSet={item.iconLg ?? item.icon} />
                              <source media="(max-width: 767px)" srcSet={item.icon ?? ""} />
                              <img title={item.title ?? ""} alt={item.title ?? ""} src={item.iconLg ?? ""} />
                           </picture>
                           <div className="cover"></div>
                        </div>

                        {/* <span>{item.title}</span> */}
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
}
